// ./components/Popup.jsx
import React, { useState } from 'react';
import useAddProducts from '../hooks/useAddProducts';




const Popup = ({ onClose, name, head_text, description, image_path, text_color, isEditing, isCopy }) => {
    const [formData, setFormData] = useState({
        name: `${name}`,
        head_text: `${head_text}`,
        description: `${description}`,
        text_color: `${text_color}`,
        image: null, // Стейт для хранения информации о файле
        requestImagepath: `${image_path}`,
    });


    const { addProduct, loading, error } = useAddProducts();

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            // Сохраняем файл в стейт
            setFormData({
                ...formData,
                image: e.target.files[0] // Присваиваем объект File
            });
        } else {
            // Обычное обновление текстовых полей
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleAddProduct = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('head_text', formData.head_text);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('text_color', formData.text_color);
            formDataToSend.append('image', formData.image); // Добавляем файл в FormData
            if (image_path){

                formDataToSend.append('requestImagepath', formData.requestImagepath)
            }
            

            const result = await addProduct(formDataToSend);
            console.log('Успешно добавлен:', result);
            onClose(); // Закрываем попап после успешного добавления
        } catch (error) {
            console.error('Ошибка при добавлении продукта:', error);
        }
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg w-full max-w-lg max-h-[60%] overflow-y-auto top-20">
                <h2 className="text-2xl mb-4">
                    {isEditing ? 'Изменение услуги' : isCopy ? `Создание копии` : `Добавить услугу`}
                    </h2>
                <form>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Название услуги</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Название услуги" 
                        name="name"
                        value={formData.name}
                        onChange={handleChange}/>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Заголовок</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Заголовок"
                        name="head_text"
                        value={formData.head_text}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Описание</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Описание"
                        name="description"
                        value={formData.description}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Цвет текста</label>
                        <select 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            name="text_color"
                            value={formData.text_color}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Выберете цвет текста:</option>
                            <option value="black">Черный</option>
                            <option value="white">Белый</option>
                        </select>
                    </div>

                    
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Поле для вставки картинки</label>
                        <input 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            type="file"
                            name="image"
                            onChange={handleChange} // Обработчик для выбора файла
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={onClose}
                        >
                            Закрыть
                        </button>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleAddProduct}
                        >
                            {isEditing ? 'Изменить' : 'Добавить'}
                        </button>
                    </div>
                </form>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        </div>
    );
};

export default Popup;
