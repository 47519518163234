import React, { useState, useEffect } from 'react';
import RowUser from './components/useRows'; 
import useGetUsers from './hooks/AddRows';

const Users = () => {
    const { users, reloadData } = useGetUsers();

    const [filters, setFilters] = useState({
        userId: '',
        name: '',
        secondName: '',
        phone: '',
        assistant: ''
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const filteredUsers = users.filter(user => 
        user.name && // Исключаем пользователей без имени
        (user.user_id.toString().includes(filters.userId) || filters.userId === '') &&
        ((user.name || '').toLowerCase().includes(filters.name.toLowerCase()) || filters.name === '') &&
        ((user.secondName || '').toLowerCase().includes(filters.secondName.toLowerCase()) || filters.secondName === '') &&
        ((user.phone || '').toLowerCase().includes(filters.phone.toLowerCase()) || filters.phone === '') &&
        ((user.name_assistant || '').toLowerCase().includes(filters.assistant.toLowerCase()) || filters.assistant === '')
    );
    const sortedUsers = filteredUsers.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
    });
    return (
        <main>
            <div className="header">
                <div className="left">
                    <h1>Пользователи</h1>
                </div>
            </div>
            <ul className="insights"></ul>
            <div className="bottom-data">
                <div className="orders">
                    <div className="header">
                        <i className='bx bx-receipt'></i>
                        <h3>Управление пользователями</h3>
                        <i className='bx bx-refresh' onClick={reloadData}></i>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID пользователя</th>
                                <th>Имя</th>
                                <th>Фамилия</th>
                                <th>Телефон</th>
                                <th>Ассистент</th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="filter">

                                    <input type="text" name="userId" value={filters.userId} onChange={handleFilterChange} placeholder="Фильтр по ID" />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">

                                    <input type="text" name="name" value={filters.name} onChange={handleFilterChange} placeholder="Фильтр по имени" />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">

                                    <input type="text" name="secondName" value={filters.secondName} onChange={handleFilterChange} placeholder="Фильтр по фамилии" />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">

                                    <input type="text" name="phone" value={filters.phone} onChange={handleFilterChange} placeholder="Фильтр по Телефону" />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">

                                    <input type="text" name="assistant" value={filters.assistant} onChange={handleFilterChange} placeholder="Фильтр по ассистенту" />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedUsers.map(user => (
                                <RowUser
                                    key={user.user_id}
                                    id={user.user_id}
                                    name={user.name}
                                    tag={user.secondName}
                                    phone= {user.phone}
                                    category={user.name_assistant}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
};

export default Users;
