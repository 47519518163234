import React from 'react';

const Card = ({ className, h3, p }) => {

    return (
        
        <li>
            <i className={`${className}`}></i>
            <span className="info">
                <h3>
                    {h3}
                </h3>
                <p>{p}</p>
            </span>
        </li>
    
    );
};

export default Card;
