// DashboardPage.js
import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import DashboardCards from './DashboardCards';
import Analytics from './Analytics';
import Products from './Products/page';
import Category from './Category/page';
import Services from './Services/page';
import Users from './Users/page';

const DashboardPage = () => {
    const [selectedComponent, setSelectedComponent] = useState('Главная');

    const handleMenuItemClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    const pages = [
        { name: 'Главная', icon: 'bxs-dashboard', active: selectedComponent === 'Главная' },
        { name: 'Одобрение', icon: 'bx-analyse', active: selectedComponent === 'Одобрение' },
        { name: 'Пользователи', icon: 'bx bxs-cog', active: selectedComponent === 'Пользователи' },
        // { name: 'Рассылка', icon: 'bx bxs-cog', active: selectedComponent === 'Рассылка' },
        { name: 'Продукты', icon: 'bx bxs-cog', active: selectedComponent === 'Продукты' },
        { name: 'Категории', icon: 'bx bxs-cog', active: selectedComponent === 'Категории' },
        { name: 'Сервисы', icon: 'bx bxs-cog', active: selectedComponent === 'Сервисы' },
        // { name: 'Просмотр заказов', icon: 'bx bxs-cog', active: selectedComponent === 'Просмотр заказов' },
        // { name: '', icon: 'bx bxs-cog', active: selectedComponent === 'Чат менеджер' },

        
    ];

    // Определение выбранного компонента
    let SelectedComponent;
    if (selectedComponent === 'Главная') {
        SelectedComponent = <DashboardCards />;
    } else if (selectedComponent === 'Одобрение') {
        SelectedComponent = <Analytics />;
    } else if (selectedComponent === 'Пользователи') {
        SelectedComponent = <Users />;
    } else if (selectedComponent === 'Продукты') {
        SelectedComponent =  <Products />
    }
    else if (selectedComponent === 'Категории'){
        SelectedComponent = <Category />
    }
    else if (selectedComponent === 'Сервисы'){
        SelectedComponent = <Services/>
    }

    return (
        <div>
            {/* Передача страниц и обработчика клика в компонент Sidebar */}
            <Sidebar pages={pages} isOpen={true} onItemClick={handleMenuItemClick} />
            <div className='content'>
                <Navbar />
                {/* Отображение выбранного компонента */}
                {SelectedComponent}
            </div>
        </div>
    );
};

export default DashboardPage;
