import { useState, useEffect } from 'react';

const useProductDel = () => {
    const [delProduct, setDelProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteProduct = async (productId) => {
        setLoading(true);
        try {
            const response = await fetch(`https://cpy-back.tutbar.online.tutbar.online/api/products/del?id=${productId}`, {
                method: 'DELETE'
            });

            if (!response.ok) {
                throw new Error('Failed to delete product');
            }

            const productData = await response.json();
            setDelProduct(productData);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { deleteProduct, delProduct, loading, error };
};

export default useProductDel;
