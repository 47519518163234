// Products.jsx
import React, { useState } from 'react';
import RowUser from './components/useRows'; 
import useGetServices from './hooks/AddRows';
import Popup from './components/Popup';
import PopupProduct from './components/popup_product';

const Services = () => {
    const { catalog, reloadData } = useGetServices();
    console.log("🚀 ~ Category ~ catalogs:", catalog)
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupProductOpen, setIsPopupProductOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState(null);

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
        reloadData();
    };

    const handlePopupProductOpen = (productId) => {
        console.log('click')
        setCurrentProductId(productId);
        setIsPopupProductOpen(true);
    };

    const handlePopupProductClose = () => {
        setIsPopupProductOpen(false);
    };

    return (
        <main>
            <div className="header">
                <div className="left">
                    <h1>Сервисы</h1>
                </div>
            </div>
            <ul className="insights">
            </ul>
            <div className="bottom-data">
                <div className="orders">
                    <div className="header">
                        <i className='bx bx-receipt'></i>
                        <h3>Изменение Сервисов</h3>
                        <i className='bx bx-plus' onClick={handlePopupOpen}></i>
                        {/* <i className='bx bx-filter'></i>
                        <i className='bx bx-search'></i> */}
                        <i className='bx bx-refresh' onClick={reloadData}></i>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название сервиса</th>

                            </tr>
                        </thead>
                        <tbody>
                            
                            {catalog.map(catalogs => (
                                <RowUser
                                    onClick={() => handlePopupProductOpen(catalogs.id)}
                                    key={catalogs.id}
                                    id={catalogs.id}
                                    name={catalogs.name}

                                    
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isPopupOpen && <Popup onClose={handlePopupClose} />}
            {isPopupProductOpen && (<PopupProduct productId={currentProductId} onClose={handlePopupProductClose}/>
            )}
        </main>
    );
};

export default Services;
