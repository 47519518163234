import React from 'react';
import Card from '../components/Statics';
import RowUser from '../components/UserRows';
import useNotApprovedUsers from '../hooks/ApprovedUsersTable';
import useNotApprovedUsersLength from '../hooks/useNotApprovedusersLengt';
import useUpdateUserRegistrationStatus from '../hooks/useApprovedAndRejectUser';
import useUserLength from '../hooks/useUsersLenght';

const Analytics = () => {
    const { length_notapproved, error: lengthError } = useNotApprovedUsersLength();
    const { notaproved, loading, error: usersError, reloadData } = useNotApprovedUsers();
    const { success, updateUserRegistrationStatus } = useUpdateUserRegistrationStatus();
    const {userLength} = useUserLength();

    if (loading) return <p>Loading...</p>;

    // Проверка на ошибки загрузки данных
    if (lengthError || usersError) {
        return (
            <main>
                <p>Error loading data: {lengthError || usersError}</p>
            </main>
        );
    }

    const handleApprove = (user_id) => {
        updateUserRegistrationStatus(user_id, 1); // 1 для одобрения
        reloadData();
    };

    const handleReject = (user_id) => {
        updateUserRegistrationStatus(user_id, 2); // 2 для отклонения
        reloadData();
    };

    return (
        <main>
            <div className="header">
                <div className="left">
                    <h1>Одобрение заявок на регистрацию</h1>
                    <ul className="breadcrumb">
                        <li>
                            <a href="#">
                                Главное
                            </a>
                        </li>
                        /
                        <li>
                            <a href="#" className="active">Одобрение заявок на регистрацию</a>
                        </li>
                    </ul>
                </div>
            </div>
            <ul className="insights">
                <Card className="bx bx-child" h3={userLength} p="Пользователей Всего" />
                <Card className="bx bx-child" h3={length_notapproved} p="Пользователей ожидающих подтверждения" />
            </ul>
            
            {/* Проверка на наличие пользователей для отображения */}
            {notaproved.length > 0 ? (
                <div className="bottom-data">
                    <div className="orders">
                        <div className="header">
                            <i className='bx bx-receipt'></i>
                            <h3>Пользователи</h3>
                            <i className='bx bx-filter'></i>
                            <i className='bx bx-search'></i>
                            <i className='bx bx-refresh' onClick={reloadData}></i>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>User_id</th>
                                    <th>Имя</th>
                                    <th>Фамилия</th>
                                    <th>Телефон</th>
                                    <th>Имя Ассистента</th>
                                    <th>Номер Ассистента</th>
                                    <th>Одобрить</th>
                                    <th>Отклонить</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notaproved.map(user => (
                                      user.name && (

                                          <RowUser
                                              key={user.id}
                                              user_id={user.user_id}
                                              Name={user.name}
                                              SecondName={user.secondName}
                                              Phone={user.phone}
                                              Assistant_name={user.name_assistant}
                                              Assistant_phone={user.phone_assistant}
                                              Approved='bx bx-check'
                                              Reject='bx bx-x'
                                              handleApprove={() => handleApprove(user.user_id)}
                                              handleReject={() => handleReject(user.user_id)}
                                              onClick={() => console.log(`Clicked user ${user.user_id}`)}
                                          />
                                      )
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="bottom-data">
                    <div className="orders">
                        <div className="header">
                            <i className='bx bx-receipt'></i>
                            <h3>Пользователи</h3>
                            <i className='bx bx-filter'></i>
                            <i className='bx bx-search'></i>
                            <i className='bx bx-refresh' onClick={reloadData}></i>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>User_id</th>
                                    <th>Имя</th>
                                    <th>Фамилия</th>
                                    <th>Телефон</th>
                                    <th>Имя Ассистента</th>
                                    <th>Номер Ассистента</th>
                                    <th>Одобрить</th>
                                    <th>Отклонить</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="8">Нет пользователей для отображения</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </main>
    );
};

export default Analytics;
