// components/RowUser.jsx
import React from 'react';
import useUpdateUserRegistrationStatus from '../hooks/useApprovedAndRejectUser'
import useNotApprovedUsers from '../hooks/ApprovedUsersTable';

const RowUser = ({user_id, Name, SecondName, Phone, Assistant_name,Assistant_phone,Approved,Reject, handleApprove, handleReject, onClick}) => {
    // const { reloadData } = useNotApprovedUsers();
    // const { loading, error, success, updateUserRegistrationStatus } = useUpdateUserRegistrationStatus();
    // const handleApprove = () => {
    //     updateUserRegistrationStatus(user_id, 1); // 1 для одобрения
    //     reloadData();
    // };

    // const handleReject = () => {
    //     updateUserRegistrationStatus(user_id, 2); // 2 для отклонения
    //     reloadData();
    // };
    return (
        <tr onClick={onClick}>
            <td>{user_id}</td>
            <td>{Name}</td>
            <td>{SecondName}</td>
            <td>{Phone}</td>
            <td>{Assistant_name}</td>
            <td>{Assistant_phone}</td>
            <td><button onClick={handleApprove} class={Approved}></button></td>
            <td><button onClick={handleReject} class={Reject}></button></td>
        </tr>
    );
};

export default RowUser;
