import React from 'react';

import Card from '../components/Statics'
import useUserLength from '../hooks/useUsersLenght';
import useNotApprovedUsersLength from '../hooks/useNotApprovedusersLengt';

const DashboardCards = () => {
    const {userLength} = useUserLength();
    const { length_notapproved, error: lengthError } = useNotApprovedUsersLength();
    return (

            
            <main>
                <div className="header">
                    <div className="left">
                        <h1>Главная</h1>
                        <ul className="breadcrumb">
                            <li>
                                <a href="#">
                                    Главная
                                </a>
                            </li>
                            /
                            <li>
                                <a href="#" className="active">Бот</a>
                            </li>
                        </ul>
                    </div>
                </div>


                <ul className="insights">
                <Card className="bx bx-child" h3={userLength} p="Пользователей" />
                <Card className="bx bx-child" h3={length_notapproved} p="Ожидающие подтверждения" />
                {/* <Card className="bx bx-line-chart" h3="14,721" p="Searches" />
                <Card className="bx bx-dollar-circle" h3="$6,742" p="Total Sales" /> */}
                

                    
                </ul>
            </main>

    );
};

export default DashboardCards;