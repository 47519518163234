import { useState, useEffect } from 'react';

const useGetProducts = () => {
    const [data, setData] = useState({ products: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0); // стейт для обновления данных

    const reloadData = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        const fetchNotApprovedUsers = async () => {
            setLoading(true); // устанавливаем состояние загрузки перед запросом
            try {
                const response = await fetch(`https://cpy-back.tutbar.online.tutbar.online/api/products/getall?refreshKey=${refreshKey}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching not approved users:', error);
                setError(error.message);
            } finally {
                setLoading(false); // сбрасываем состояние загрузки после получения данных
            }
        };

        fetchNotApprovedUsers();
    }, [refreshKey]); // зависимость от refreshKey, чтобы перезагружать данные при его изменении

    return { ...data, loading, error, reloadData };
};

export default useGetProducts;
