// ./components/PopupProduct.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popup from './Popup';
import useProductDel from '../hooks/useProductDel';

const PopupProduct = ({ productId, onClose }) => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState('services');
    const { deleteProduct } = useProductDel();

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`https://cpy-back.tutbar.online.tutbar.online/api/services/get?id=${productId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch product details');
                }
                const productData = await response.json();
                setProduct(productData.services[0]); // Assuming products is an array with one product
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        if (productId) {
            fetchProductDetails();
        }
    }, [productId]);

    if (loading) {
        return <p>Loading...</p>; // Отображаем заглушку во время загрузки
    }

    if (error) {
        return <p>Error fetching product details: {error.message}</p>; // Отображаем ошибку, если она произошла
    }



    
    if (currentPage === 'editServices') {
        return <Popup 
        onClose={onClose}  
        name={product.name !== null ? product.name : ''} 
        head_text={product.head_text !== null ? product.head_text : ''} 
        description={product.description !== null ? product.description : ''} 
        image_path = {product.image_path !== null ? product.image_path : ''} 
        text_color = {product.text_color !== null ? product.text_color : ''} 
        isEditing = {true}
    />
    }
    if (currentPage === 'CopyServices'){
        return <Popup 
        onClose={onClose}  
        name={product.name !== null ? product.name : ''} 
        head_text={product.head_text !== null ? product.head_text : ''} 
        description={product.description !== null ? product.description : ''} 
        image_path = {product.image_path !== null ? product.image_path : ''} 
        text_color = {product.text_color !== null ? product.text_color : ''} 
        isCopy = {true}
    />
    }

    const handleDelete = async () => {
        await deleteProduct(productId);
        onClose();
    };

    const handleEditClick = async() => {
        
        setCurrentPage('editServices');
        await deleteProduct(productId);

    };

    const handleCopy =() =>{
        
        setCurrentPage('CopyServices');
    };


    // Если данные получены успешно, отображаем информацию о товаре
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg w-full max-w-lg max-h-[80%] overflow-y-auto top-50">
                <div className="relative mb-4">
                    <img
                        src={`https://cpy-back.tutbar.online.tutbar.online/${product.image_path}`}
                        alt={product.name}
                        className="w-full h-64 object-cover rounded-t-lg"
                    />
                    <div className="absolute top-0 right-0 m-0">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded focus:outline-none focus:shadow-outline"
                            onClick={onClose}
                        >
                            <i class='bx bx-x'></i>
                        </button>
                    </div>
                </div>
                <div className="p-4">
                    <h2 className="text-2xl mb-4">{product.name}</h2>
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Заголовок:</strong> {product.head_text}
                        </p>
                    </div>
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Описание:</strong> {product.description}
                        </p>
                    </div>
                    {/* <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Цвет текста:</strong> {product.text_color}
                        </p>
                    </div> */}
                        <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleDelete}
                        >
                            Удалить
                        </button>

                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleEditClick}
                        >
                            Изменить
                        </button>

                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleCopy}
                        >
                            Создать копию
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

PopupProduct.propTypes = {
    productId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PopupProduct;
