
import React from 'react';

const RowUser = ({id, tag, name, brand, cost,color,user, onClick, girlandman}) => {

    return (
        <tr onClick={onClick}>
            <td>{id}</td>
            <td>{tag}</td>
            <td>{girlandman}</td>
            <td>{name}</td>
            <td>{brand}</td>
            <td>{cost}</td>
            <td>{color}</td>
            <td>{user}</td>
        </tr>
    );
};

export default RowUser;
