import { useState, useEffect } from 'react';
import axios from 'axios';

const useNotApprovedUsersLength = () => {
    const [length_notapproved, setLength] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNotApprovedUsersLength = async () => {
            try {
                const response = await axios.get('https://cpy-back.tutbar.online.tutbar.online/api/users/notapproved');
                const users = response.data.notaproved;

               // Проверяем наличие данных в ответе и фильтруем пользователей с ненулевым name
               if (users && Array.isArray(users)) {
                const filteredUsers = users.filter(user => user.name !== null && user.name.trim() !== '');
                setLength(filteredUsers.length);
            } else {
                setLength(0); // Устанавливаем 0, если данных нет или они не являются массивом
            }
            } catch (err) {
                console.error('Error fetching not approved users:', err);
                if (err.response && err.response.status === 500) {
                    // Ошибка 500 - внутренняя серверная ошибка
                    setError('Internal server error occurred');
                    setLength(0); // Устанавливаем 0 в случае ошибки 500
                } else {
                    setError(err.message);
                    setLength(0); // Устанавливаем 0 в случае других ошибок
                }
            }
        };

        fetchNotApprovedUsersLength();
    }, []);

    return { length_notapproved, error };
};

export default useNotApprovedUsersLength;
