
import React from 'react';

const RowUser = ({id, name, tag,phone, category, onClick}) => {

    return (
        <tr onClick={onClick}>
            <td>{id}</td>
            <td>{name}</td>
            <td>{tag}</td>
            <td>{phone}</td>
            <td>{category}</td>
        </tr>
    );
};

export default RowUser;
